import React from "react"
import styles from "./styles.module.less"
import Header from "./../components/Header"
import Intro from "./../components/IntroFlex"
import MadLib from "./../components/MadLib"
import { YuleLog, Singalong } from "./../components/Video"
import ErrorPage from "../components/ErrorPage"
import WhiteBody from "../components/BodyHl/WhiteBody"
import Closing from "../components/Closing"
import Container from "../components/Container"
import Music from "../components/Music"

import gift from "../components/images/oregon.png"

const NikeGlobalApp = () => {
  const importAll = r => {
    return r.keys().map(r)
  }

  const listOfImages = importAll(
    require.context("./img/team", false, /\.(png|jpe?g|svg)$/)
  )

  return (
    <div className={styles.NikeGlobalApp}>
      <Header client="Nike" />
      <Intro
        header={
          <>
            We love working
            <br />
            with you.
          </>
        }
      >
        <p>
          As your devoted digital partner,
          <br className={styles.mobileHide} />
          we can’t wait to work with you more in 2021.
          <br />
          <br />
          So just call out our name <br className={styles.mobileHide} />
          and you know, wherever we are
          <br className={styles.mobileHide} />
          we'll come running to help you again.{" "}
          <br className={styles.mobileHide} />
          With strategy, writing, design, <br className={styles.mobileHide} />
          performance, dev of any kind—
          <br className={styles.mobileHide} />
          oh, we'll be there.
          <br />
          <br />
          ...ye ye yeah...
        </p>
      </Intro>
      <WhiteBody
        preheader="Thesis + Nike"
        headline={`Until there's work,\nlet's play.`}
      />
      <Container>
        <div className={styles.gridContainer}>
          <div className={styles.grid}>
            <div
              className={styles.box}
              style={{
                width: "231.15px",
                height: "285.07px",
                left: "110.83px",
                top: "99.85px",
              }}
            >
              <Music />
            </div>

            <div
              className={styles.box}
              style={{
                width: "501.75px",
                height: "336px",
                left: "381.93px",
                top: "99.85px",
              }}
            >
              <Singalong />
            </div>

            <div
              className={styles.box}
              style={{
                width: "501.75px",
                height: "315.03px",
                left: "110.83px",
                top: "485.77px",
              }}
            >
              <YuleLog />
            </div>

            <div
              className={styles.box}
              style={{
                width: "233.15px",
                height: "405.89px",
                left: "652.52px",
                top: "485.77px",
              }}
            >
              <MadLib />
            </div>

            <div
              className={styles.box}
              style={{
                width: "501.75px",
                height: "316.52px",
                left: "110.83px",
                top: "850.72px",
              }}
            >
              <ErrorPage />
            </div>
          </div>
        </div>
      </Container>
      <Closing
        images={listOfImages}
        outroImg={gift}
        outroCopy={
          <>
            Through the marvel of digital marketing, we’ve saved a lot of trees
            together this year. To keep up that good work, we’ve donated in your
            name to The Nature Conservancy in Oregon, whose mission is to
            protect the lands and waters on which all life depends.
            <br />
            <br />
            Thank you for helping set us on the path to a more
            hopeful,&nbsp;sustainable future.
          </>
        }
      />
    </div>
  )
}

export default NikeGlobalApp
